import React, { useState } from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import { LoadingBanner, ErrorBanner } from '../Components/Helpers/index.js';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from "yup"
import ConfirmUpdateDevice from './confirmupdatedevice.js';

export default function EditDevicePage(props) {
    // HOOKS
    const currentLocation = useLocation();
    const { modelId } = useParams();
    const navigate = useNavigate();

    // STATE

    const [formLoading, setFormLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);

    // FIRESTORE
    const firestore = useFirestore();
    const documentRef = doc(firestore, 'devicemodels', modelId);
    const { status, data: device } = useFirestoreDocData(documentRef);

    // FORMS

    const initialValues = { concentrations: Array.isArray(device.concentrations) ? device.concentrations.map(value => Number(value).toFixed(2)) : [], method: (device.method !== undefined) ? device.method : 'N/A', gasType: device.gasType, presetLimit: device.presetLimit, validityCount: device.validityCount, manufacturer: device.manufacturer, displayname: device.displayname, calrangeGl: (device.calrange.calrangeGl !== undefined) ? device.calrange.calrangeGl : '', calrangeMgl: (device.calrange.calrangeMgl !== undefined) ? device.calrange.calrangeMgl : '', calrangeBac: (device.calrange.calrangeBac !== undefined) ? device.calrange.calrangeBac : '', ledStyle: device.ledStyle };
    const validationSchema = Yup.object({
        manufacturer: Yup.string("Enter manufacturer").required("Required"),
        displayname: Yup.string("Enter model").required("Required"),
        calrangeGl: Yup.string("Enter range").required("Required"),
        calrangeMgl: Yup.string("Enter range").required("Required"),
        calrangeBac: Yup.string("Enter range").required("Required"),
        validityCount: Yup.number("Enter number").required("Required").min(0).max(200000).typeError('A number is required'),
        presetLimit: Yup.string("Enter limit").required("Required"),
        gasType: Yup.string("Enter gas type").required("Required"),
        method: Yup.string("Enter calibration method").required("Required"),
        ledStyle: Yup.bool("Enter device kind").optional(),
        concentrations: Yup.array().of(Yup.number().oneOf([0.02, 0.03, 0.05, 0.08, 0.10], "Invalid value")).min(1, "Select at least one concentration")
    });

    async function handleSubmit(form) {
        closeErrorBanner();
        setFormLoading(true);

        // munge into own object
        var calrange = {}
        if (form.calrangeGl !== null && form.calrangeGl !== "") {
            calrange.calrangeGl = form.calrangeGl;
        }
        if (form.calrangeMgl !== null && form.calrangeMgl !== "") {
            calrange.calrangeMgl = form.calrangeMgl;
        }
        if (form.calrangeBac !== null && form.calrangeBac !== "") {
            calrange.calrangeBac = form.calrangeBac;
        }

        const ledStyle = form.ledStyle === true ? true : false;

        await updateDoc(documentRef, {
            manufacturer: form.manufacturer,
            displayname: form.displayname,
            calrange: calrange,
            validityCount: form.validityCount,
            presetLimit: form.presetLimit,
            gasType: form.gasType,
            ledStyle: ledStyle,
            method: form.method,
            concentrations: form.concentrations.map(Number)
        }).catch((error) => {
            setFormLoading(false);
            showErrorBanner("Error: could not update device model " + error.message);
            console.log(error);
        }).then(() => {
            setFormLoading(false);
            showSuccessDialog();
        });
    }

    // LIFTING STATE

    const handleConfirmChange = (newVal) => {
        setSuccessDialogOpen(newVal);

        if (newVal === false) {
            hideSuccessDialog();
        }
    }

    // HELPERS

    function showSuccessDialog() {
        setFormLoading(false);
        setSuccessDialogOpen(true);
    }

    function hideSuccessDialog() {
        setSuccessDialogOpen(false);
        navigate('/calibrations/admin/devices'); // outta here
    }

    function showErrorBanner(message) {
        setLastErrorMessage(message);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    if (status === 'loading') {
        return <span>Loading...</span>;
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                    <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                        <div>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">The selected device's details appear below.</p>
                        </div>
                    </div>

                    <ConfirmUpdateDevice open={successDialogOpen} onChange={handleConfirmChange} />

                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                        {({ handleSubmit, handleChange, handleBlur, isValid, dirty, values, initialValues }) => (
                            <form onSubmit={handleSubmit} className="">

                                {/* Device area */}
                                <div className="mt-6 bg-white shadow overflow-y-visible sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Device model information
                                            {formLoading && <LoadingBanner />}
                                            {errorBannerOpen && <ErrorBanner message={lastErrorMessage} />}
                                        </h3>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4">
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Manufacturer: &nbsp; <ErrorMessage name="manufacturer" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="manufacturer" id="manufacturer" autoComplete="off" placeholder='Optional' defaultValue={initialValues.manufacturer} onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Model: &nbsp; <ErrorMessage name="displayname" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="displayname" id="displayname" autoComplete="off" placeholder='Optional' defaultValue={initialValues.displayname} onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Accuracy range:&nbsp;
                                                    <ErrorMessage name="calrangeBac" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input type="text" name="calrangeBac" id="calrangeBac" defaultValue={initialValues.calrangeBac} onChange={handleChange} onBlur={handleBlur}
                                                            className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                                % BAC
                                                            </span>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Accuracy range:&nbsp;
                                                    <ErrorMessage name="calrangeGl" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input type="text" name="calrangeGl" id="calrangeGl" defaultValue={initialValues.calrangeGl} onChange={handleChange} onBlur={handleBlur}
                                                            className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                                g/L
                                                            </span>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Accuracy range:&nbsp;
                                                    <ErrorMessage name="calrangeMgl" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input type="text" name="calrangeMgl" id="calrangeMgl" defaultValue={initialValues.calrangeMgl} onChange={handleChange} onBlur={handleBlur}
                                                            className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                                mg/L
                                                            </span>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Validity:&nbsp;
                                                    <ErrorMessage name="validityCount" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input type="text" name="validityCount" id="validityCount" defaultValue={initialValues.validityCount} onChange={handleChange} onBlur={handleBlur}
                                                            className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                                tests
                                                            </span>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Pre-set PASS/FAIL limit: &nbsp; <ErrorMessage name="presetLimit" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="presetLimit" id="presetLimit" autoComplete="off" defaultValue={initialValues.presetLimit} onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Calibration method: &nbsp; <ErrorMessage name="method" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="method" id="method" autoComplete="off" defaultValue={initialValues.method} onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    CRM type: &nbsp; <ErrorMessage name="gasType" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 py-2">
                                                        <div class="flex items-center">
                                                            <input id="wet" name="gasType" value="Wet gas" type="radio" onChange={handleChange} onBlur={handleBlur} defaultChecked={initialValues.gasType === 'Wet gas'} class="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                                            <label for="wet" class="ml-3 block text-sm text-gray-700"> Wet gas </label>
                                                        </div>

                                                        <div class="flex items-center">
                                                            <input id="dry" name="gasType" value="Dry gas" type="radio" onChange={handleChange} onBlur={handleBlur} defaultChecked={initialValues.gasType === 'Dry gas'} class="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                                            <label for="dry" class="ml-3 block text-sm text-gray-700"> Dry gas </label>
                                                        </div>

                                                        <div class="flex items-center">

                                                            <input id="na" name="gasType" value="N/A" type="radio" onChange={handleChange} onBlur={handleBlur} defaultChecked={initialValues.gasType === 'N/A'} class="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                                            <label for="na" class="ml-3 block text-sm text-gray-700"> N/A </label>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Valid concentrations &nbsp;
                                                    { /*JSON.stringify(values.concentrations)*/ }
                                                    <ErrorMessage name="concentrations" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-5 py-2">
                                                        <div class="flex items-center">
                                                            <input id="gas02" name="concentrations" value="0.02" type="checkbox" onChange={handleChange} onBlur={handleBlur} checked={values.concentrations.includes("0.02")} class="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                                            <label for="gas02" class="ml-3 block text-sm font-medium text-gray-700"> 0.02% </label>
                                                        </div>

                                                        <div class="flex items-center">
                                                            <input id="gas03" name="concentrations" value="0.03" type="checkbox" onChange={handleChange} onBlur={handleBlur} checked={values.concentrations.includes("0.03")} class="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                                            <label for="gas03" class="ml-3 block text-sm font-medium text-gray-700"> 0.03% </label>
                                                        </div>

                                                        <div class="flex items-center">
                                                            <input id="gas05" name="concentrations" value="0.05" type="checkbox" onChange={handleChange} onBlur={handleBlur} checked={values.concentrations.includes("0.05")} class="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                                            <label for="gas05" class="ml-3 block text-sm font-medium text-gray-700"> 0.05% </label>
                                                        </div>

                                                        <div class="flex items-center">
                                                            <input id="gas08" name="concentrations" value="0.08" type="checkbox" onChange={handleChange} onBlur={handleBlur} checked={values.concentrations.includes("0.08")} class="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                                            <label for="gas08" class="ml-3 block text-sm font-medium text-gray-700"> 0.08% </label>
                                                        </div>

                                                        <div class="flex items-center">
                                                            <input id="gas10" name="concentrations" value="0.10" type="checkbox" onChange={handleChange} onBlur={handleBlur} checked={values.concentrations.includes("0.10")} class="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                                            <label for="gas10" class="ml-3 block text-sm font-medium text-gray-700"> 0.10% </label>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Device indicators: &nbsp; <ErrorMessage name="ledStyle" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10 py-2">
                                                        <div class="flex items-center">
                                                            <input id="ledStyle" name="ledStyle" value={true} type="checkbox" defaultChecked={initialValues.ledStyle} onChange={handleChange} onBlur={handleBlur} class="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300" />
                                                            <label for="ledStyle" class="ml-3 block text-sm text-gray-700"> Use LED indicators </label>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>

                                        </dl>
                                    </div>
                                </div>

                                {/* Button actions */}
                                <div className="mt-4 flex justify-end">
                                    <button type="button" onClick={() => navigate(-1)} className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                        Cancel
                                    </button>
                                    <button type="submit" disabled={formLoading || !dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-900">
                                        Update {/* disabled={!dirty || !isValid} */}
                                    </button>
                                </div>

                            </form>
                        )}
                    </Formik>

                </div>
            </div>
        </main>
    )
}