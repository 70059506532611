import React, { useState, useEffect } from 'react';
import { formatDateAbbr } from '../../Shared/Components/functions.js';
import { usePaginatedQueue } from './usePaginatedQueue.js';
import QueueActionDialog from './queueactiondialog.js';
import ModalLoadingActivity from '../Components/ModalLoadingActivity.js';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getFirestore, doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { getIdToken } from "firebase/auth";
import { useAuth } from 'reactfire';

export default function QueueTab(props) {
    // STATE
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedCertificates()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [checkResult, setCheckResult] = useState(undefined);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [spinnerOpen, setSpinnerOpen] = useState(false);
    const resultsPerPage = 20;

    // FIRESTORE
    const calibrations = usePaginatedQueue(referenceItem, include, resultsPerPage);
    const currentLastVisibleItem = (calibrations !== undefined) ? calibrations.docs[calibrations.size - 1] : null;
    const db = getFirestore();
    const storage = getStorage();
    const auth = useAuth();

    // PAGINATION HELPERS

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, calibrations.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (calibrations !== undefined && calibrations.size % resultsPerPage === 0) {
            setNextDisabled(false);
        } else if (calibrations !== undefined) {
            setNextDisabled(true);
        }
    }, [firstItemStack, calibrations]);

    // OTHER HELPERS

    async function fileExists(certificateGuid) {
        const fileRef = ref(storage, `certificates/${certificateGuid}.pdf`);
        try {
            await getDownloadURL(fileRef);
            return true; // file does exist
        } catch (error) {
            if (error.code === 'storage/object-not-found') {
                return false;
            }

            // some other error but will return false since result is not truthy
            return false;
        }
    }

    // ACTION HANDLERS

    const checkItemStatus = (calibrationItem) => {
        setSpinnerOpen(true);

        // for date calculations might need to make
        const now = new Date();
        const fortyFiveDaysAgo = new Date();
        fortyFiveDaysAgo.setDate(now.getDate() - 45);

        // check if certificate guid is set
        if (calibrationItem.data().certificateGuid !== undefined) {
            if (fileExists(calibrationItem.data().certificateGuid)) {
                setCheckResult("has_generated");
            } else {
                setCheckResult("not_generated");
            }
        } else if (calibrationItem.data().dts.toDate() < fortyFiveDaysAgo) {
            setCheckResult("too_old");
        } else {
            setCheckResult("not_generated");
        }

        setSelectedItemId(calibrationItem.id);
        setDialogOpen(true);
        setSpinnerOpen(false);
    };

    const handleRetry = async (calibrationGuid) => {
        console.log("Retrying queue for", calibrationGuid);
        let docIdRegex = /^[A-Za-z0-9]{10,30}$/;

        try {
            const calibrationDocRef = doc(db, "calibrations", calibrationGuid);
            const docSnap = await getDoc(calibrationDocRef);
            if (docSnap.exists()) {
                const deviceGuid = docSnap.data().deviceGuid;
                const crmGuid = docSnap.data().crmGuid;

                if (!deviceGuid || !crmGuid || !docIdRegex.test(deviceGuid) || !docIdRegex.test(crmGuid)) {
                    alert("Invalid device or CRM identifiers");
                    setDialogOpen(false);
                    return;
                }

                // construct payload
                const payload = {};
                const payloadItems = [];
                payloadItems.push({ 'calibrationGuid': calibrationGuid, 'deviceGuid': deviceGuid, 'crmGuid': crmGuid });
                payload.calibrations = payloadItems;

                // need some field that is checked in the queue so that Check button changes to Pending
                await updateDoc(calibrationDocRef, { reEnqueueDts: serverTimestamp() });

                // now submit the form
                await getIdToken(auth.currentUser, false)
                    .then(jwtToken => {
                        fetch('https://isoberdev.web.app/cal/enqueue', { method: 'POST', headers: { "Content-type": "application/json; charset=UTF-8", "Authorization": "Bearer " + jwtToken, }, body: JSON.stringify(payload) })
                            .then(response => response.json())
                            .then(data => {
                                if (data.error) {
                                    window.alert(data.message);
                                } else {
                                    window.alert("Calibration has been re-queued for generation.");
                                }
                            });
                    })
                    .catch((errorJwt) => {
                        window.alert("Error - invalid auth token");
                    });
            } else {
                window.alert("Error re-queuing calibration, document does not exist");
            }
        } catch (error) {
            window.alert(`Error re-queuing calibration: ${error.message}`);
        } finally {
            setDialogOpen(false);
        }
    };

    // observed several instances where certificateGuid is set but certificateGenerated = false and certificateQueued = true
    // need to troubleshoot the root cause but fix here in the queue is to flip those flags to what they should have been if
    // certificate which we know has been generated was done.
    const handleRemove = async (calibrationGuid) => {
        try {
            const calibrationDocRef = doc(db, "calibrations", calibrationGuid);
            const docSnap = await getDoc(calibrationDocRef);
            if (docSnap.exists()) {
                await updateDoc(calibrationDocRef, { certificategenerated: true, certificatequeued: false });
                console.log("Document updated successfully");
            } else {
                window.alert("Error updating document: ", "Document does not exist");
            }
        } catch (error) {
            window.alert("Error updating document: ", error);
        } finally {
            setDialogOpen(false);
        }
    };

    // LIFTED FROM QueueActionDialog

    const actionHandler = (action) => {
        if (action === 'remove') {
            handleRemove(selectedItemId);
        } else if (action === 'retry') {
            handleRetry(selectedItemId);
        }

        // else dialogOpen stays true but is no longer visible and won't show again thereafter
        setDialogOpen(false);
        setSelectedItemId(undefined);
    }

    return (
        <div>
            {/* Progress spinner */}
            {spinnerOpen && <ModalLoadingActivity isOpen={spinnerOpen} message="Please stand by..." />}

            {/* Results area */}
            {calibrations && calibrations.docs.length > 0 && <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Certificate generation queue</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">The following calibration certificates are queued for generation. Calibrations remain in the <i>pending</i> state for 15 minutes.</p>
                </div>

                {calibrations.size > 0 && <div>
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Calibration date</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Model</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer name</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Serials</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Technician</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {calibrations.docs.map((calibrationItem, itemIdx) => (
                                <tr key={calibrationItem.id} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                    <td className="pl-6 py-4">{calibrationItem.data().dts !== undefined && formatDateAbbr(calibrationItem.data().dts.toDate())}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{calibrationItem.data().modelName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {calibrationItem.data().certificateCustomerName}<br />
                                        {/*Debug: calibrationItem.id*/}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        <span className="">Unit serial: {calibrationItem.data().unitserial ? calibrationItem.data().unitserial : "—"}</span><br />
                                        <span className="">Sensor serial: {calibrationItem.data().sensorserial ? calibrationItem.data().sensorserial : "—"}</span><br />
                                        <span className="">MAC address: {calibrationItem.data().macaddress ? calibrationItem.data().macaddress : "—"}</span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        {calibrationItem.data().authUserName !== undefined ? calibrationItem.data().authUserName : 'Not set'}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                                        {/* when re-enqueing we set reEnqueueDts so that we don't show Check on something that might just have been re-enqueued */}
                                        {calibrationItem.data().dts &&
                                            (new Date() - calibrationItem.data().dts.toDate() > 15 * 60 * 1000) &&
                                            (!calibrationItem.data().reEnqueueDts || new Date() - calibrationItem.data().reEnqueueDts.toDate() > 15 * 60 * 1000) ? (
                                            <button onClick={() => checkItemStatus(calibrationItem)}
                                                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-isober-1000 bg-isober-50 hover:bg-isober-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                                Check
                                            </button>
                                        ) : (
                                            <span className="text-gray-500">Pending</span>
                                        )}

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}

                {/* empty onClose handler prevents clicking outside the dialog to close */}
                {dialogOpen === true && selectedItemId !== undefined && <QueueActionDialog handler={actionHandler} open={dialogOpen} status={checkResult} />}
            </div>

            }

            {/* Empty state */}
            {calibrations.docs.length === 0 && <div className="mt-6 relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <div className="text-center">
                    <p className="text-lg leading-6 font-medium text-gray-900">No calibrations are in the queue</p>
                    <p className="mt-2 text-sm text-gray-500">
                        Calibrations that are in the queue to have certificates generated will appear here as they are received.
                    </p>
                </div>
            </div>}

            {/* Pagination controls */}
            {calibrations.docs.length > 0 &&
                <nav
                    className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{(firstItemStack.length * resultsPerPage) + resultsPerPage}</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        {firstItemStack.length > 1 &&
                            <button onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                        }

                        <button onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>
                        <button onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                    </div>
                </nav>
            }

        </div>
    )
}