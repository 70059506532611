import React, { useState, useRef, useEffect } from 'react';
import CrmChooser from '../Components/CrmChooser.js';
import CalibrationsByDeviceSerials from './bydeviceserials.js';
import ConfirmScrap from './confirmscrap';
import ConfirmRepair from './confirmrepair';
import AddNoteDialog from './addnotedialog';
import BackdateDialog from './backdatedialog';
import ActionsMenuControl from './actionsmenucontrol';
import { LoadingBanner, ErrorBanner, InfoBanner, ButtonBanner } from '../Components/Helpers/index.js';
import ConfirmCalibrate from './confirmcalibrate.js';
import { getIdToken } from "firebase/auth";
import { useAuth } from 'reactfire';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from "yup"
import { useFirestore } from 'reactfire';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import UnitChooser from './unitchooser.js';
import ValidityChooser from './validitychooser.js';
import { formatDate } from '../../Shared/Components/functions.js'
import { useStickyCrm } from './useStickyCrm.js';
import PrintLabelOverlay from '../Calibrations/printdialog.js';
import useModel from '../Components/Hooks/usemodel.js';
import { format } from 'date-fns';

// Parameters:
// CalibrateFragment
//   * props.device              Device record
//   * props.jobcard             Job card record
//   * props.userinfo            User info record
//   * props.cancelHandler()     Cancel handler

export default function CalibrateFragment(props) {
    // HOOKS
    const auth = useAuth();
    const formikRef = useRef(null);
    const firestore = useFirestore();
    const defaultCrmId = useStickyCrm(props.userinfo.id, props.device.modelId);
    const deviceModel = useModel(props.device.modelId);

    useEffect(() => {
        if (props.device !== undefined && props.device.status === 'Calibration complete') {

            if (props.device.calibrationDts !== undefined) {
                const calibrationDts = formatDate(props.device.calibrationDts.toDate());
                showErrorBanner(`Note: this device's status was set to 'Calibration complete' at ${calibrationDts}.`)
            } else {
                showErrorBanner("Note: this device's status is 'Calibration complete'.")
            }
        }
    }, [props.device])

    // STATE
    const [showDeviceHistory, setShowDeviceHistory] = useState(false);
    const [deviceHistoryQuery, setDeviceHistoryQuery] = useState(null);
    const [confirmRepairOpen, setConfirmRepairOpen] = useState(false)
    const [confirmScrapOpen, setConfirmScrapOpen] = useState(false)
    const [addNoteOpen, setAddNoteOpen] = useState(false)
    const [backdateOpen, setBackdateOpen] = useState(false)
    const [formLoading, setFormLoading] = useState(false);
    const [formAlreadySubmitted, setFormAlreadySubmitted] = useState(false);
    const [calibrationSuccessDialogOpen, setCalibrationSuccessDialogOpen] = useState(false);
    const [scrapSuccessDialogOpen, setScrapSuccessDialogOpen] = useState(false);
    const [printOverlayOpen, setPrintOverlayOpen] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);
    const [unitScale, setUnitScale] = useState("mg/L");
    const [csvRecord, setCsvRecord] = useState(undefined);
    const [backdateDate, setBackdateDate] = useState(undefined);
    const [crmReference, setCrmReference] = useState(undefined);

    // FUNCTIONS

    function updateDeviceHistory() {
        const p = [];

        if (formikRef.current.values.sensorserial !== null && formikRef.current.values.sensorserial !== undefined && formikRef.current.values.sensorserial.trim() !== "") {
            p.push(formikRef.current.values.sensorserial);
        }

        if (formikRef.current.values.unitserial !== null && formikRef.current.values.unitserial !== undefined && formikRef.current.values.unitserial.trim() !== "") {
            p.push(formikRef.current.values.unitserial);
        }

        if (formikRef.current.values.macaddress !== null && formikRef.current.values.macaddress !== undefined && formikRef.current.values.macaddress.trim() !== "") {
            p.push(formikRef.current.values.macaddress);
        }

        if (p.length > 0) {
            setDeviceHistoryQuery(p);
            setShowDeviceHistory(true);
        } else {
            setDeviceHistoryQuery(null);
            setShowDeviceHistory(true);
        }
    }

    // LIFTING STATE

    const handleReferenceMaterialChange = (newVal, setFieldValue, reference = undefined) => {
        setFieldValue('crmGuid', newVal); // use passed function to set formik field value

        // if CrmChooser calls handleReferenceMaterialChange with its reference, set it here
        if (reference !== undefined && !isNaN(reference)) {
            setCrmReference(reference);
        }

    }

    const handleCalibrationConfirmChange = (newVal) => {
        setCalibrationSuccessDialogOpen(newVal);

        if (newVal === false) {
            hideCalibrationSuccessDialog();
        }
    }

    const handleScrapConfirmChange = (newVal) => {
        setScrapSuccessDialogOpen(newVal);

        if (newVal === false) {
            hideScrapSuccessDialog();
        }
    }

    const handleUnitScaleChange = (newVal, setFieldValue) => {
        setFieldValue('unitScale', newVal); // use passed function to set formik field value
        setUnitScale(newVal); // update form labels
    }

    const handleValidityChange = (newVal, setFieldValue) => {
        setFieldValue('validity', newVal); // use passed function to set formik field value
        //setUnitScale(newVal); // update form labels
    }

    // HELPERS

    function cancelPressed() {
        props.cancelHandler();
    }

    function showCalibrationSuccessDialog() {
        setFormLoading(false);
        setCalibrationSuccessDialogOpen(true);
    }

    function hideCalibrationSuccessDialog() {
        setCalibrationSuccessDialogOpen(false);
        props.cancelHandler();
    }

    function showScrapSuccessDialog() {
        setScrapSuccessDialogOpen(true);
    }

    function hideScrapSuccessDialog() {
        setScrapSuccessDialogOpen(false);
        props.cancelHandler();
    }

    function showErrorBanner(message) {
        setLastErrorMessage(message);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    const addNoteHandler = () => {
        setAddNoteOpen(true);
    }

    const showBackdateDialog = () => {
        setBackdateOpen(true);
    }

    const backdateHandler = async (date) => {
        setBackdateOpen(false);

        if (date instanceof Date) {
            setBackdateDate(date);
        }
    }

    const clearBackdate = () => {
        setBackdateDate(undefined);
    }

    const confirmScrap = () => {
        setConfirmScrapOpen(true);
    }

    const confirmRepair = () => {
        setConfirmRepairOpen(true);
    }

    const handleScrapOpenChange = async (proceedApproved) => {
        setConfirmScrapOpen(false);

        // if proceed is false then don't proceed
        if (!proceedApproved || proceedApproved === false) {
            return;
        }

        if (!props.userinfo || !props.userinfo.fname || !props.userinfo.lname) {
            showErrorBanner("Error – user info is not set, can't continue.");
            return;
        }

        closeErrorBanner();
        setFormLoading(true);

        // create payload
        var payload = {
            authUserName: `${props.userinfo.fname} ${props.userinfo.lname}`,
            jobcardGuid: props.jobcard.id,
            deviceGuid: props.device.id,
            modelName: props.device.model,
            clientGuid: props.jobcard.clientGuid,
            // FIXME form field into req.body.sensorserial
            // FIXME form field into req.body.unitserial
        }

        await getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://isoberdev.web.app/cal/scrap', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    },
                    body: JSON.stringify(payload)
                })
                    .catch((err) => {
                        setFormLoading(false);
                        showErrorBanner("Error " + err);
                    })
                    .then(response => response.json())
                    .then(data => {
                        setFormLoading(false);
                        if (data.error) {
                            showErrorBanner(data.message);
                        } else {
                            setFormLoading(false);
                            showScrapSuccessDialog();
                        }
                    });
            })
            .catch((errorJwt) => {
                setFormLoading(false);
                showErrorBanner("Error - invalid auth token");
            });
    }

    const handleRepairOpenChange = (newVal) => {
        setConfirmRepairOpen(newVal);
    }

    const addNoteChangeSaveHandler = async (note) => {
        closeErrorBanner();

        // validation
        if (!props.jobcard || !props.jobcard.id) {
            showErrorBanner("Error - invalid job card ID");
            return;
        }

        // validation
        if (!props.userinfo || !props.userinfo.fname || !props.userinfo.lname) {
            showErrorBanner("Error – user info is not set, can't continue.");
            return;
        }

        if (!auth.currentUser.uid) {
            showErrorBanner("Error – user info is not set, can't continue.");
            return;
        }

        setFormLoading(true);

        const notesCollection = collection(firestore, `jobcards/${props.jobcard.id}/notes`);
        await addDoc(notesCollection, {
            userGuid: auth.currentUser.uid,
            userName: `${props.userinfo.fname} ${props.userinfo.lname}`,
            created: serverTimestamp(),
            note: note,
        });

        setFormLoading(false);
    }

    const closeAddNoteHandler = () => {
        setAddNoteOpen(false);
    }

    const handlePrint = () => {
        setCalibrationSuccessDialogOpen(false);
        setPrintOverlayOpen(true);
    }

    const handlePrintClosed = () => {
        setCalibrationSuccessDialogOpen(true);
        setPrintOverlayOpen(false);
    }

    // FORMS

    // formik needs different initial values for LED style devices than otherwise
    const initialPrecalAlcohol = (!props.device.ledStyle) ? '' : '0';
    const initialCalValue = (!props.device.ledStyle) ? '' : "All LEDs";
    const initialPostcalReading = (!props.device.ledStyle) ? '' : '3';
    const initialPrecalZero = (!props.device.ledStyle) ? '0.000' : '0';
    const initialPostcalZero = (!props.device.ledStyle) ? '0.000' : '0';

    const initialValues = { postcalreading: initialPostcalReading, precalalcohol: initialPrecalAlcohol, precalzero: initialPrecalZero, validity: '12 months', deviceGuid: props.device.id, jobcardGuid: props.jobcard.id, modelName: props.device.model, modelGuid: props.device.modelId, clientGuid: props.jobcard.clientGuid, clientName: props.jobcard.clientName, calvalue: initialCalValue, certificateCustomerName: (props.jobcard.certificateClientName !== undefined) ? props.jobcard.certificateClientName : props.jobcard.clientName, certificateNote: '', unitserial: props.device.serial, sensorserial: props.device.sensorSerial, macaddress: '', readingscount: '', postcalzero: initialPostcalZero, unitScale: unitScale, maximum: false, ledStyle: (props.device.ledStyle != null && props.device.ledStyle === true) ? true : false, overrideValidityCount: deviceModel.validityCount ? deviceModel.validityCount : 0, overridePassLimit: deviceModel.presetLimit ? deviceModel.presetLimit : "N/A" };
    const validationSchema = Yup.object({
        readingscount: Yup.number("Enter measurements count").optional().typeError('Must be numeric').min(0, "Invalid number"),
        sensorserial: Yup.string().test(function (value) {
            const { unitserial } = this.parent;
            if (!unitserial) return value != null
            return true
        }),
        unitserial: Yup.string().test(function (value) {
            const { sensorserial } = this.parent;
            if (!sensorserial) return value != null
            return true
        }),
        macaddress: Yup.string("Enter a MAC address").optional().matches(/^((([0-9A-F]{2}:){5})|(([0-9A-F]{2}-){5})|([0-9A-F]{10}))([0-9A-F]{2})$/i, "Invalid"),
        jobcardGuid: Yup.string("Choose job card guid").required("Required"),
        deviceGuid: Yup.string("Choose device guid").required("Required"),
        clientGuid: Yup.string("Choose client").required("Required"),
        clientName: Yup.string("Choose client").required("Required"),
        certificateCustomerName: Yup.string("Enter customer name").required("Required"),
        certificateNote: Yup.string("Enter a note").optional(),
        precalzero: Yup.number("Enter pre cal zero").required("Required").typeError('Invalid'),
        precalalcohol: Yup.number("Enter pre cal alcohol").required("Required").typeError('Invalid'),
        calvalue: Yup.string("Enter sensor condition").required("Required").typeError('Invalid'),
        postcalzero: Yup.number("Enter post cal zero").required("Required").typeError('Invalid'),
        postcalreading: Yup.number("Enter post cal reading").required("Required").typeError('Invalid'),
        temperature: Yup.number("Enter temperature value").required("Required").min(5).max(99).typeError('Invalid'),
        humidity: Yup.number("Enter humidity value").required("Required").min(5).max(99).typeError('Invalid'),
        modelGuid: Yup.string("Choose device model").required("Required"),
        modelName: Yup.string("Enter a model name").optional(),
        crmGuid: Yup.string("Choose CRM item").required("Required"),
        technicianNote: Yup.string("Enter a note").optional(),
        unitScale: Yup.string("Choose a scale").required().oneOf(['mg/L', '% BAC', 'g/L']),
        validity: Yup.string("Choose a validity period").required().oneOf(['6 months', '12 months']),
        maximum: Yup.bool().optional(),
        ledStyle: Yup.bool().required(),
        overrideValidityCount: Yup.number("Enter validity count").optional().typeError('Invalid'),
        overridePassLimit: Yup.string("Enter a pass/fail limit").optional(),
    });

    async function handleSubmit(form) {
        setFormLoading(true);
        closeErrorBanner();

        // merge user display name into form
        if (props.userinfo && props.userinfo.fname && props.userinfo.lname) {
            form.authUserName = `${props.userinfo.fname} ${props.userinfo.lname}`;
        }

        // merge backdated calibration date into form
        if (backdateDate !== undefined && backdateDate instanceof Date) {
            form.backdate = yyyymmdd(backdateDate);
        }

        const csv = {
            certificateCustomerName: form.certificateCustomerName,
            modelName: form.modelName,
            unitserial: form.unitserial,
            sensorserial: form.sensorserial,
            readingscount: form.readingscount,
            ledStyle: form.ledStyle,
            unitScale: form.unitScale,
            precalzero: form.precalzero,
            precalalcohol: form.precalalcohol,
            calvalue: form.calvalue,
            postcalzero: form.postcalzero,
            postcalreading: form.postcalreading,
            temperature: form.temperature,
            humidity: form.humidity,
            crm: form.crmGuid,
            technicianNote: form.technicianNote,
            macaddress: form.macaddress, // for label
            authUserName: form.authUserName, // for label
            validity: form.validity, // for label
            backdateString: (form.backdate) ? form.backdate : undefined,
            // dts is handled by label when it is undefined
        };

        await getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://isoberdev.web.app/cal/calibrate', {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    },
                    body: JSON.stringify(form)
                })
                    .catch((err) => {
                        setFormLoading(false);
                        showErrorBanner("Error " + err);
                    })
                    .then(response => response.json())
                    .then(data => {
                        setFormLoading(false);
                        if (data.error) {
                            showErrorBanner(data.message);
                        } else {
                            setCsvRecord(csv);
                            showCalibrationSuccessDialog();
                            setFormAlreadySubmitted(true);
                        }
                    });
            })
            .catch((errorJwt) => {
                setFormLoading(false);
                showErrorBanner("Error - invalid auth token");
            });
    }

    const yyyymmdd = (indate) => {
        var mm = indate.getMonth() + 1; // getMonth() is zero-based
        var dd = indate.getDate();

        return [indate.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
        ].join('/');
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div>
            <ConfirmScrap open={confirmScrapOpen} onScapOpenChange={handleScrapOpenChange} />
            <ConfirmRepair open={confirmRepairOpen} onRepairOpenChange={handleRepairOpenChange} />
            <AddNoteDialog open={addNoteOpen} closeHandler={closeAddNoteHandler} saveHandler={addNoteChangeSaveHandler} />
            <BackdateDialog open={backdateOpen} saveHandler={backdateHandler} />

            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="mt-2 max-w-4xl text-sm text-gray-500">&nbsp;</p>
                </div>
                <div className="mt-3 flex sm:mt-0 sm:ml-4">
                    <button type="button" onClick={updateDeviceHistory} className="mr-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                        {!showDeviceHistory && "Show device history"}
                        {showDeviceHistory && "Update device history"}
                    </button>

                    <ActionsMenuControl item1Handler={addNoteHandler} item3Handler={confirmScrap} item4Handler={confirmRepair} backdateHandler={showBackdateDialog} />
                </div>
            </div>

            {calibrationSuccessDialogOpen === true && <ConfirmCalibrate open={calibrationSuccessDialogOpen} onChange={handleCalibrationConfirmChange} item={csvRecord} showCopyCsv={true} handlePrint={handlePrint} />}
            {scrapSuccessDialogOpen === true && <ConfirmCalibrate open={scrapSuccessDialogOpen} onChange={handleScrapConfirmChange} bodyMessage="The unit was successfully marked as scrapped." showCopyCsv={false} />}
            {csvRecord !== undefined && <PrintLabelOverlay open={printOverlayOpen} onPrintClosed={handlePrintClosed} item={csvRecord} />}

            <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                {({ handleSubmit, handleChange, handleBlur, isValid, dirty, setFieldValue, setFieldTouched, values }) => (
                    <form onSubmit={handleSubmit} className="">

                        {/* Company and device details area */}
                        <div className="mt-6 bg-white shadow overflow-y-visible sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Device and Customer</h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">Calibrating {(['a', 'e', 'i', 'o', 'u', 'A', 'E', 'I', 'O', 'U'].includes(props.device.model[0])) ? 'an' : 'a'} <span className='font-bold'>{props.device.model}</span> for <span className='font-bold'>{props.jobcard.clientName}</span>, received on {formatDate(props.jobcard.created.toDate())}.
                                    Customer name on certificate will be <span className='font-bold'>{values.certificateCustomerName}</span> per the job card.</p>

                                {/*formikRef && formikRef.current && formikRef.current.errors && <p className="mt-1 max-w-2xl text-sm text-gray-500">Errors: {JSON.stringify(formikRef.current.errors)}</p>*/}

                                {formLoading && <LoadingBanner />}
                                {errorBannerOpen && <ErrorBanner message={lastErrorMessage} />}
                                {props.device.note !== undefined && <InfoBanner message={`Receiving note: ${props.device.note}`} />}
                                {backdateDate !== undefined && <ButtonBanner message={`Calibration date on the certificate date will be ` + format(backdateDate, 'MMMM dd, yyyy')} handler={clearBackdate} />}
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4">

                                    <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Unit serial &nbsp; <ErrorMessage name="unitserial" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900"><input type="text" name="unitserial" id="unitserial" onChange={handleChange} onBlur={handleBlur} defaultValue={initialValues.unitserial} onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }} autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" /></dd>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Sensor serial &nbsp; <ErrorMessage name="sensorserial" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900"><input type="text" name="sensorserial" id="sensorserial" onChange={handleChange} onBlur={handleBlur} defaultValue={initialValues.sensorserial} onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }} autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" /></dd>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            MAC address &nbsp; <ErrorMessage name="macaddress" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900"><input type="text" name="macaddress" id="macaddress" placeholder="Optional" onChange={handleChange} onBlur={handleBlur} onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }} autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" /></dd>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Measurements count &nbsp; <ErrorMessage name="readingscount" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="flex items-center mt-1 text-sm text-gray-900">
                                            <input type="text" name="readingscount" id="readingscount" placeholder="Optional" disabled={values.maximum === true} onChange={handleChange} onBlur={handleBlur} onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }} autoComplete="off" className="block w-full disabled:text-gray-400 border border-gray-300 rounded-md shadow-sm mr-6 py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                            <div className="relative flex items-start">
                                                <div className="h-5"><input id="maximum" aria-describedby="offers-description" name="maximum" type="checkbox" onChange={handleChange} onBlur={handleBlur} className="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300 rounded" /></div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="maximum" className="text-gray-700">Maximum</label>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>

                        {/* Calibration details area */}
                        <div className="mt-6 bg-white shadow overflow-y-visible sm:rounded-lg">
                            <div className="relative px-4 py-5 sm:px-6 mb-8 mx-5">
                                <div className='absolute left-0'>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Calibration</h3>
                                </div>

                                <div className='flex absolute right-0 px-6'>
                                    <UnitChooser handler={handleUnitScaleChange} sfv={setFieldValue} />
                                </div>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-10">
                                    {!props.device.ledStyle && <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Pre-cal zero &nbsp; <ErrorMessage name="precalzero" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <div className="mt-1 relative rounded-md shadow-sm">
                                                <input type="text" name="precalzero" id="precalzero" onChange={handleChange} onBlur={handleBlur} defaultValue={initialValues.precalzero} onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                                                    className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                        {unitScale}
                                                    </span>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>}

                                    {props.device.ledStyle && props.device.ledStyle === true && <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Pre-cal Zero &nbsp; <ErrorMessage name="x1" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd>
                                            <fieldset className="mt-4">
                                                <div className="space-y-4">
                                                    <div key="x1a" className="flex items-center">
                                                        <input id="x1" name="precalzero" value="0" type="radio" defaultChecked={true} onChange={handleChange} onBlur={handleBlur} className="h-4 w-4 border-gray-300 text-isober-600 focus:ring-isober-500" />
                                                        <label htmlFor="x1" className="ml-3 block text-sm font-medium text-gray-700">
                                                            PASS
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </dd>
                                    </div>}

                                    {!props.device.ledStyle && <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Pre-cal alcohol &nbsp; <ErrorMessage name="precalalcohol" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <div className="mt-1 relative rounded-md shadow-sm">
                                                <input type="text" name="precalalcohol" id="precalalcohol" onChange={handleChange} onBlur={handleBlur} onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                                                    className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                        {unitScale}
                                                    </span>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>}

                                    {props.device.ledStyle && props.device.ledStyle === true && <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Pre-cal alcohol &nbsp; <ErrorMessage name="x2" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd>
                                            <fieldset className="mt-4">
                                                <div className="space-y-4">
                                                    <div key="x2a" className="flex items-center">
                                                        <input id="x2a" name="precalalcohol" value="0" type="radio" defaultChecked={true} onChange={handleChange} onBlur={handleBlur} className="h-4 w-4 border-gray-300 text-isober-600 focus:ring-isober-500" />
                                                        <label htmlFor="x2a" className="ml-3 block text-sm font-medium text-gray-700">
                                                            PASS
                                                        </label>
                                                    </div>
                                                    <div key="x2b" className="flex items-center">
                                                        <input id="x2b" name="precalalcohol" value="1" type="radio" defaultChecked={false} onChange={handleChange} onBlur={handleBlur} className="h-4 w-4 border-gray-300 text-isober-600 focus:ring-isober-500" />
                                                        <label htmlFor="x2b" className="ml-3 block text-sm font-medium text-gray-700">
                                                            WARN
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </dd>
                                    </div>}

                                    {!props.device.ledStyle && <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Sensor condition &nbsp; <ErrorMessage name="calvalue" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <div className="mt-1 relative rounded-md shadow-sm">
                                                <input type="text" name="calvalue" id="calvalue" onChange={handleChange} onBlur={handleBlur} onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                                                    className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-3 sm:text-sm border-gray-300 rounded-md" />
                                            </div>
                                        </dd>
                                    </div>}

                                    {props.device.ledStyle && props.device.ledStyle === true && <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Sensor condition &nbsp; <ErrorMessage name="x3" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <fieldset className="mt-4">
                                                <div className="space-y-4">
                                                    <div key="x3a" className="flex items-center">
                                                        <input id="x3a" name="calvalue" value="ALL LEDs" type="radio" defaultChecked={true} onChange={handleChange} onBlur={handleBlur} className="h-4 w-4 border-gray-300 text-isober-600 focus:ring-isober-500" />
                                                        <label htmlFor="x3a" className="ml-3 block text-sm font-medium text-gray-700">
                                                            ALL LEDs
                                                        </label>
                                                    </div>
                                                    <div key="x3b" className="flex items-center">
                                                        <input id="x3b" name="calvalue" value="Red LED" type="radio" defaultChecked={false} onChange={handleChange} onBlur={handleBlur} className="h-4 w-4 border-gray-300 text-isober-600 focus:ring-isober-500" />
                                                        <label htmlFor="x3b" className="ml-3 block text-sm font-medium text-gray-700">
                                                            Red LED
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </dd>
                                    </div>}

                                    {!props.device.ledStyle && <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Post-cal reading &nbsp; <ErrorMessage name="postcalreading" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <div className="mt-1 relative rounded-md shadow-sm">
                                                <input type="text" name="postcalreading" id="postcalreading" onChange={handleChange} onBlur={handleBlur} onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }} className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                        {unitScale}
                                                    </span>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>}

                                    {props.device.ledStyle && props.device.ledStyle === true && <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Post-cal Alcohol &nbsp; <ErrorMessage name="x5" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd>
                                            <fieldset className="mt-4">
                                                <div className="space-y-4">
                                                    <div key="x5a" className="flex items-center">
                                                        <input id="x5" name="postcalreading" value="3" type="radio" defaultChecked={true} onChange={handleChange} onBlur={handleBlur} className="h-4 w-4 border-gray-300 text-isober-600 focus:ring-isober-500" />
                                                        <label htmlFor="x5" className="ml-3 block text-sm font-medium text-gray-700">
                                                            WARN/FAIL
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </dd>
                                    </div>}

                                    {!props.device.ledStyle && <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Post-cal Zero &nbsp; <ErrorMessage name="postcalzero" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <div className="mt-1 relative rounded-md shadow-sm">
                                                <input type="text" name="postcalzero" id="postcalzero" onChange={handleChange} onBlur={handleBlur} defaultValue={initialValues.postcalzero} onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                                                    className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                        {unitScale}
                                                    </span>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>}

                                    {props.device.ledStyle && props.device.ledStyle === true && <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Post-cal Zero &nbsp; <ErrorMessage name="x4" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd>
                                            <fieldset className="mt-4">
                                                <div className="space-y-4">
                                                    <div key="x4a" className="flex items-center">
                                                        <input id="x4" name="postcalzero" value="0" type="radio" defaultChecked={true} onChange={handleChange} onBlur={handleBlur} className="h-4 w-4 border-gray-300 text-isober-600 focus:ring-isober-500" />
                                                        <label htmlFor="x4" className="ml-3 block text-sm font-medium text-gray-700">
                                                            PASS
                                                        </label>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </dd>
                                    </div>}

                                    <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Temperature &nbsp; <ErrorMessage name="temperature" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <div className="mt-1 relative rounded-md shadow-sm">
                                                <input type="text" name="temperature" id="temperature" onChange={handleChange} onBlur={handleBlur} onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                                                    className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                        °C
                                                    </span>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Humidity &nbsp; <ErrorMessage name="humidity" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <div className="mt-1 relative rounded-md shadow-sm">
                                                <input type="text" name="humidity" id="humidity" onChange={handleChange} onBlur={handleBlur} onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                                                    className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                        %
                                                    </span>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>
                                    <div className="sm:col-span-6">
                                        <dt className="text-sm text-gray-500">
                                            <span className='font-medium'>Certified reference material</span> &nbsp;
                                            {Array.isArray(deviceModel.concentrations) && (
                                                <span
                                                    className={classNames(
                                                        "text-xs",
                                                        crmReference === undefined
                                                            ? ""
                                                            : deviceModel.concentrations.some(
                                                                (value) => parseFloat(value) === parseFloat(crmReference)
                                                            )
                                                                ? "text-green-700"
                                                                : "text-red-500"
                                                    )}
                                                >
                                                    {props.device.model} uses{" "}
                                                    {deviceModel.concentrations
                                                        .map((value) => `${parseFloat(value).toFixed(2)}%`)
                                                        .join(", ")}
                                                </span>
                                            )}
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <CrmChooser handler={handleReferenceMaterialChange} sfv={setFieldValue} defaultId={defaultCrmId} />
                                        </dd>
                                    </div>

                                    <div className="sm:col-span-4">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Override device pass/fail limit &nbsp; <ErrorMessage name="overridePassLimit" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900"><input type="text" name="overridePassLimit" id="overridePassLimit" defaultValue={initialValues.overridePassLimit} placeholder="Optional" onChange={handleChange} onBlur={handleBlur} onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }} autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" /></dd>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Override device validity &nbsp; <ErrorMessage name="overrideValidityCount" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <div className="mt-1 relative rounded-md shadow-sm">
                                                <input type="text" name="overrideValidityCount" id="overrideValidityCount" defaultValue={initialValues.overrideValidityCount} onChange={handleChange} onBlur={handleBlur} autoComplete="off" onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                                                    className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                        Tests
                                                    </span>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Calibration validity &nbsp; <ErrorMessage name="validitycount" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <ValidityChooser handler={handleValidityChange} sfv={setFieldValue} />
                                        </dd>
                                    </div>

                                    <div className="sm:col-span-10">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Technician internal note &nbsp; <ErrorMessage name="technicianNote" component="span" className="text-red-700 italic" />
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <input type="text" name="technicianNote" id="technicianNote" onChange={handleChange} onBlur={handleBlur} onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }} placeholder="Optional" autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>

                        {/* Button actions */}
                        <div className="mt-4 flex justify-end">
                            <button type="submit" onClick={cancelPressed} className="mr-2 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                {formAlreadySubmitted && "Done"}
                                {!formAlreadySubmitted && "Cancel"}
                            </button>

                            <button type="submit" disabled={formLoading || formAlreadySubmitted || !dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-900">
                                Submit calibration
                            </button>
                        </div>
                    </form>
                )}
            </Formik>

            {/* Debug 
            <p className='mt-6'>{JSON.stringify(props.device)}</p> */}

            {/* Show device history */}

            {Array.isArray(deviceHistoryQuery) && deviceHistoryQuery.length > 0 && showDeviceHistory && <CalibrationsByDeviceSerials query={deviceHistoryQuery} heading={'Device calibration history:'} userinfo={props.userinfo} />}
            {!Array.isArray(deviceHistoryQuery) && showDeviceHistory && <p className='mt-4'>Enter a unit serial, sensor serial or MAC address</p>}
        </div >
    )
}