import React, { useState } from 'react';
import PageHeader from '../Components/PageHeader/index.js';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, ErrorMessage, Field } from 'formik';
import * as Yup from "yup";
import { doc, updateDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useAuth, useFirestore, useFirestoreDocData } from 'reactfire';
import ConfirmUpdateCrm from './confirmupdatecrm.js';
import ConfirmDecactivateCrm from './confirmdeactivatecrm.js';
import { LoadingBanner, ErrorBanner } from '../Components/Helpers/index.js';
import DevicesAsCheckboxes from '../Components/DeviceChooser/AsCheckboxes.js';
import { formatDateDMY } from '../../Shared/Components/functions.js';
import { BranchControl } from '../../Stock/BranchControl';

export default function EditCrmPage(props) {
    // HOOKS
    const auth = useAuth();
    const currentLocation = useLocation();
    const navigate = useNavigate();
    const { crmId } = useParams();

    // FIRESTORE
    const firestore = useFirestore();
    const documentRef = doc(firestore, 'calibrationcrm', crmId);
    const { status, data: crm } = useFirestoreDocData(documentRef);

    // STATE

    const [formLoading, setFormLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [lastErrorMessage, setLastErrorMessage] = useState(null);
    const [confirmDeactivateCrmOpen, setConfirmDeactivateCrmOpen] = useState(false)
    const [guidToRemove, setGuidToRemove] = useState(null)

    // FORMS

    const initialValues = { branchId: crm.branchId, bottlenumber: crm.bottlenumber, bottlevolume: crm.bottlevolume, expiry: formatDateDMY(crm.expiry.toDate()), lotnumber: crm.lotnumber, lotvolume: crm.lotvolume, manufacture: crm.manufacture, reference: crm.reference, supplier: crm.supplier, validdevices: (crm.validfor !== undefined && crm.validfor !== null && Array.isArray(crm.validfor)) ? crm.validfor : [] };
    const validationSchema = Yup.object({
        bottlevolume: Yup.string("Enter botlew volume").required("Required"),
        expiry: Yup.string("Enter expiry date").required("Required").test("email-include-domain", 'Invalid date format', (value) => stringToDate(value)),
        lotnumber: Yup.string("Enter lot number").required("Required"),
        lotvolume: Yup.string("Enter lot volume").required("Required"),
        manufacture: Yup.string("Enter manufacture date").required("Required"),
        reference: Yup.number("Enter reference").oneOf([0.02, 0.03, 0.05, 0.08, 0.10], "Invalid value").required("Required").typeError("A number is required"),
        supplier: Yup.string().oneOf(['Guth Laboratories', 'NMISA', 'Air Liquide'], 'Invalid selection').required('Required'),
        validdevices: Yup.array().required(),
        branchId: Yup.string().oneOf(["jhb", "ct", "dbn", "bw", "cs"]).required(),
    });

    async function handleSubmit(form) {
        closeErrorBanner();
        setFormLoading(true);

        // a) perform the update
        await updateDoc(documentRef, {
            bottlevolume: form.bottlevolume,
            expiry: stringToDate(form.expiry),
            lotnumber: form.lotnumber,
            lotvolume: form.lotvolume,
            manufacture: form.manufacture,
            reference: form.reference,
            supplier: form.supplier,
            validfor: form.validdevices,
            branchId: form.branchId
        }).catch((error) => {
            setFormLoading(false);
            showErrorBanner("Error: could not update device model " + error.message);
            console.log(error);
        });

        // b) log this event
        const logConfiguration = collection(firestore, 'calibrationlogs');
        var userDisplayName = "Not set";
        var bottlenumber = initialValues.bottlenumber ? initialValues.bottlenumber : 'not set';
        if (props.userinfo && props.userinfo.fname && props.userinfo.lname) {
            userDisplayName = `${props.userinfo.fname} ${props.userinfo.lname}`;
        }
        await addDoc(logConfiguration, {
            user: auth.currentUser.uid,
            dts: serverTimestamp(),
            reference: documentRef.id,
            success: true,
            action: "Edit CRM",
            detail: `Bottle number: ${bottlenumber}`,
            username: userDisplayName
        });

        setFormLoading(false);
        showSuccessDialog();
    }

    async function deactivate(docId) {
        setFormLoading(true);

        // a) mark as deactivated
        const documentRef = doc(firestore, 'calibrationcrm', docId);
        await updateDoc(documentRef, {
            deleted: true
        });

        // b) log this event
        const logConfiguration = collection(firestore, 'calibrationlogs');
        var userDisplayName = "Not set";
        var bottlenumber = initialValues.bottlenumber ? initialValues.bottlenumber : 'not set';
        if (props.userinfo && props.userinfo.fname && props.userinfo.lname) {
            userDisplayName = `${props.userinfo.fname} ${props.userinfo.lname}`;
        }
        await addDoc(logConfiguration, {
            user: auth.currentUser.uid,
            dts: serverTimestamp(),
            reference: docId,
            success: true,
            action: "Deactivate CRM",
            detail: `Bottle number: ${bottlenumber}`,
            username: userDisplayName
        });

        // outta here
        window.alert("Update complete");
        setFormLoading(false);
        setGuidToRemove(null); // clear flag
    }

    // HELPERS

    function confirmDeactivate(guid) {
        console.log(`confirmDeactivate(${guid})`);
        setGuidToRemove(guid);
        setConfirmDeactivateCrmOpen(true);
    }

    function stringToDate(dateString) {

        // First check for the pattern
        if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
            return null;
        }

        // Parse the date parts to integers
        var datePieces = dateString.split("/");
        var day = parseInt(datePieces[0], 10);
        var month = parseInt(datePieces[1], 10);
        var year = parseInt(datePieces[2], 10);

        // Check the ranges of month and year
        if (year < 2020 || year > 2040 || month === 0 || month > 12) {
            return false;
        }

        // days in month then adjust Feb for leap years
        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLength[1] = 29;
        }

        // Check the range of the day
        if (day === 0 || day > monthLength[month - 1]) {
            return false;
        }

        let retval = new Date(datePieces[2], (datePieces[1] - 1), datePieces[0], 23, 59, 0);

        if (retval instanceof Date && !isNaN(retval)) {
            return retval;
        }

        return (null);
    }

    function showSuccessDialog() {
        setFormLoading(false);
        setSuccessDialogOpen(true);
    }

    function hideSuccessDialog() {
        setSuccessDialogOpen(false);
        navigate('/calibrations/admin/crm'); // outta here
    }

    function showErrorBanner(message) {
        setLastErrorMessage(message);
        setErrorBannerOpen(true);
    }

    function closeErrorBanner() {
        setLastErrorMessage(null);
        setErrorBannerOpen(false);
    }

    // LIFTING STATE

    const handleConfirmDeactivateChange = (newVal) => {
        if (newVal === true && guidToRemove !== undefined) {
            deactivate(guidToRemove);
            setConfirmDeactivateCrmOpen(false);
        } else {
            setGuidToRemove(null); // clear flag
            setConfirmDeactivateCrmOpen(false);
        }
    }

    const handleConfirmChange = (newVal) => {
        setSuccessDialogOpen(newVal);

        if (newVal === false) {
            hideSuccessDialog();
        }
    }

    const branchChangeHandler = (newValue, setFieldValue, setFieldTouched) => {
        if (setFieldTouched) {
            setFieldTouched('branchId');
        }

        if (setFieldValue) {
            setFieldValue('branchId', newValue.branch.id);
        }
    }

    // LOADING

    if (status === 'loading') {
        return "Loading";
    }

    // console.log("[EditCrmPage] initial selection: " + JSON.stringify(initialValues.validdevices));

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                        {({ handleSubmit, handleChange, handleBlur, isValid, dirty, values, isSubmitting, setFieldValue, setFieldTouched }) => (
                            <form onSubmit={handleSubmit} className="">

                                <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <p className="mt-2 max-w-4xl text-sm text-gray-500">Update a CRM lookup item using the information below.</p>
                                    </div>
                                    <div className="mt-3 flex sm:mt-0 sm:ml-4">
                                        <button type="button" onClick={() => confirmDeactivate(crmId)} className="mr-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                            Consumed
                                        </button>
                                    </div>
                                </div>

                                <ConfirmUpdateCrm open={successDialogOpen} onConfirmOpenChange={handleConfirmChange} />
                                <ConfirmDecactivateCrm open={confirmDeactivateCrmOpen} onConfirmDeactivateOpenChange={handleConfirmDeactivateChange} />

                                {/* Calibration details area */}
                                <div className="mt-6 bg-white shadow overflow-y-visible sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Certified Reference Material</h3>
                                        {formLoading && <LoadingBanner />}
                                        {errorBannerOpen && <ErrorBanner message={lastErrorMessage} />}
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Assigned bottle number &nbsp;
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="bottlenumber" id="bottlenumber" defaultValue={initialValues.bottlenumber} disabled={true} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Brand supplier &nbsp;
                                                    <ErrorMessage name="supplier" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <Field as="select" name="supplier" id="supplier" defaultValue={initialValues.supplier} onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm">
                                                        <option value="">Select</option>
                                                        <option value="Guth Laboratories">Guth Laboratories</option>
                                                        <option value="NMESA">NMISA</option>
                                                        <option value="Air Liquide">Air Liquide</option>
                                                    </Field>
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Reference amount (e.g. 0.05) &nbsp;
                                                    <ErrorMessage name="reference" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input type="text" name="reference" id="reference" defaultValue={initialValues.reference} onChange={handleChange} onBlur={handleBlur}
                                                            className="focus:ring-isober-500 focus:border-isober-500 block w-full pl-3 pr-12 sm:text-sm border-gray-300 rounded-md" />
                                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                                %
                                                            </span>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Lot number &nbsp;
                                                    <ErrorMessage name="lotnumber" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="lotnumber" id="lotnumber" defaultValue={initialValues.lotnumber} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Manufacture date &nbsp;
                                                    <ErrorMessage name="manufacture" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="manufacture" id="manufacture" autoComplete="off" defaultValue={crm.manufacture} onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Expiry date (DD/MM/YYYY) &nbsp;
                                                    <ErrorMessage name="expiry" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="expiry" id="expiry" autoComplete="off" defaultValue={initialValues.expiry} onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Lot volume &nbsp;
                                                    <ErrorMessage name="lotvolume" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="lotvolume" id="lotvolume" autoComplete="off" defaultValue={initialValues.lotvolume} onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Bottle volume &nbsp;
                                                    <ErrorMessage name="bottlevolume" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <input type="text" name="bottlevolume" id="bottlevolume" autoComplete="off" defaultValue={initialValues.bottlevolume} onChange={handleChange} onBlur={handleBlur} className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    At branch &nbsp;
                                                    <ErrorMessage name="branchId" component="span" className="text-red-700 italic" />
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <BranchControl className="overflow-y-auto" initialValue={initialValues.branchId} sfv={setFieldValue} sft={setFieldTouched} handler={branchChangeHandler} />
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>


                                {/* Valid devices area */}
                                <div className="mt-6 bg-white shadow overflow-hidden sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Valid for the following devices</h3>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <Field name="validdevices" component={DevicesAsCheckboxes} initialSelection={initialValues.validdevices} />
                                    </div>
                                </div>

                                {/* Button actions */}
                                <div className="mt-4 flex justify-end">
                                    <Link to="/calibrations/admin/crm">
                                        <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                            Cancel
                                        </button>
                                    </Link>
                                    <button type="submit" disabled={isSubmitting || !dirty || !isValid} className="disabled:opacity-50 disabled:bg-isober-1000 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-isober-900 hover:bg-isober-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-900">
                                        Save CRM
                                    </button>
                                </div>

                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </main>
    )
}