import { collection, query, orderBy, where, Timestamp } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

export function useRecentCalibrations() {
    const firestore = useFirestore();
    const calibrationsCollection = collection(firestore, 'calibrations');

    // Calculate the timestamp for 7 days ago
    const now = new Date();
    now.setHours(0, 0, 0, 0); // Reset time to start of the current day
    const sevenDaysAgo = new Date(now);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    // Convert to Firestore Timestamp
    const sevenDaysAgoTimestamp = Timestamp.fromDate(sevenDaysAgo);

    console.log(sevenDaysAgoTimestamp);

    // Query calibrations from the last 7 days
    const activeQuery = query(
        calibrationsCollection, 
        where('dts', '>=', sevenDaysAgoTimestamp), 
        orderBy('dts', 'desc'),
    );

    // Fetch data once, without real-time updates
    const { status, data } = useFirestoreCollectionData(activeQuery, { idField: 'id', subscribe: false });

    if (status === 'loading') {
        return undefined;
    }

    return data ?? [];
}
