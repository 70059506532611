import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, XIcon } from '@heroicons/react/outline'
import useModel from '../Components/Hooks/usemodel'
import useCrm from '../Components/Hooks/useCrm'

export default function DetailsOverlay(props) {
    // HOOKS
    const deviceModel = useModel(props.item.modelGuid);
    const crm = useCrm(props.item.crmGuid);

    // STATE
    const [open, setOpen] = useState(props.open)

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    // HELPERS

    function closeDialog() {
        setOpen(false);
        props.onDetailsOpenChange(false);
    }

    function formatLedStyle(n) {
        switch (n) {
            case "0":
                return "PASS";
            case "1":
                return "WARN";
            case "2":
                return "FAIL";
            case "3":
                return "WARN/FAIL";
            default:
                return "UNKNOWN";
        }
    }

    // FUNCTIONS

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeDialog}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    type="button"
                                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500"
                                    onClick={() => closeDialog()}
                                >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-isober-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationIcon className="h-6 w-6 text-isober-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Calibration Details
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        {props.item.authUserName && <p className="text-sm text-gray-500">Calibration performed by {props.item.authUserName}.</p>}
                                        {!props.item.authUserName && <p className="text-sm text-gray-500">The details for the selected calibration are as follows:</p>}
                                    </div>

                                    <dl className="sm:divide-y sm:divide-gray-200">
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Device model</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {props.item && props.item && props.item.modelName ? props.item.modelName : "—"}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Accuracy specification</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {deviceModel.calrange.calrangeBac !== undefined && <p>{deviceModel.calrange.calrangeBac}</p>}
                                                {deviceModel.calrange.calrangeMgl !== undefined && <p>{deviceModel.calrange.calrangeMgl}</p>}
                                            </dd>
                                        </div>

                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Pre-cal</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                Zero: &nbsp;

                                                {(props.item.ledStyle === null || props.item.ledStyle === false) && props.item.precalzero && props.item.unitScale && `${props.item.precalzero} ${props.item.unitScale}`}
                                                {(props.item.ledStyle !== null && props.item.ledStyle === true) && props.item.precalzero && formatLedStyle(props.item.precalzero)} <br />

                                                Alcohol: &nbsp;

                                                {(props.item.ledStyle === null || props.item.ledStyle === false) && props.item.precalalcohol && props.item.unitScale && `${props.item.precalalcohol} ${props.item.unitScale}`}
                                                {(props.item.ledStyle !== null && props.item.ledStyle === true) && props.item.precalalcohol && formatLedStyle(props.item.precalalcohol)} <br />
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">CRM used</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {crm !== undefined && `Bottle ${crm.bottlenumber} (${crm.reference}%) at ${crm.branchId.toUpperCase()}`}
                                                {crm === undefined && "Unknown"}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Sensor condition</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {props.item && props.item.calvalue ? props.item.calvalue : "—"}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Post-cal</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                Zero: &nbsp;

                                                {(props.item.ledStyle === null || props.item.ledStyle === false) && props.item.postcalzero && props.item.unitScale && `${props.item.postcalzero} ${props.item.unitScale}`}
                                                {(props.item.ledStyle !== null && props.item.ledStyle === true) && props.item.postcalzero && formatLedStyle(props.item.postcalzero)} <br />

                                                Alcohol: &nbsp;

                                                {(props.item.ledStyle === null || props.item.ledStyle === false) && props.item.postcalreading && props.item.unitScale && `${props.item.postcalreading} ${props.item.unitScale}`}
                                                {(props.item.ledStyle !== null && props.item.ledStyle === true) && props.item.postcalreading && formatLedStyle(props.item.postcalreading)} <br />
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Environment</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                Temperature: {props.item && props.item.temperature && `${props.item.temperature} °C`} <br />
                                                Humidity: {props.item && props.item.humidity && `${props.item.humidity} %`}
                                            </dd>
                                        </div>

                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Other</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {props.item.overridePassLimit && <div>Pass/fail limit: {props.item.overridePassLimit}</div>}
                                                {props.item.overrideValidityCount && <div>Validity count: {props.item.overrideValidityCount}</div>}

                                                {!props.item.overridePassLimit && <div>Pass/fail limit: {deviceModel.presetLimit}</div>}
                                                {!props.item.overrideValidityCount && <div>Validity count: {deviceModel.validityCount}</div>}
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-600 text-base font-medium text-white hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => closeDialog()}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}