import { useState } from "react";
import { useParams } from "react-router-dom";
import ModalLoadingActivity from "./ModalLoadingActivity";

export default function ValidatePage() {
    const { certificateGuid } = useParams();
    const isGuidValid = (guid) => /^[A-Za-z0-9]{10,30}$/.test(guid); // CD0nYwW18H6sIHfIowTy

    const [pin, setPin] = useState("");
    const [displayError, setDisplayError] = useState(null);
    const [isPinValid, setIsPinValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [responseData, setResponseData] = useState(null);

    const handlePinChange = (event) => {
        const value = event.target.value;
        setPin(value);
        setIsPinValid(/^[a-zA-Z0-9]{5}$/.test(value));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            const response = await fetch("https://isober.co.za/app/cc", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ guid: certificateGuid, pin: pin }),
            });

            const responseData = await response.json();

            if (!responseData || responseData.error === true) {
                setResponseData(null);
                setDisplayError(responseData.message || "An unknown error occurred.");
            } else {
                setResponseData(responseData.data);
                setDisplayError(null);
            }

            setIsLoading(false);
        } catch (err) {
            setDisplayError("An error occurred while fetching the certificate details.");
            setIsLoading(false);
        }
    }

    return (
        <>
            {isLoading && <ModalLoadingActivity isOpen={isLoading} message="Fetching details" />}

            <div className="bg-gray-50 flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img src="/images/LogoWide.png" className="mx-auto h-20 w-auto" alt="" />
                    <h2 className="mt-6 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
                        Validate Calibration Certificate
                    </h2>
                </div>

                <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-6 shadow sm:rounded-lg sm:px-12">
                        {(!responseData && isGuidValid(certificateGuid) === false) && <p>Invalid ID</p>}
                        {(!responseData && isGuidValid(certificateGuid) === true) && <form onSubmit={handleSubmit} method="POST" className="space-y-6">
                            <div>
                                <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                                    Enter PIN
                                </label>
                                <div className="mt-2">
                                    <input id="pin" name="pin" type="pin" required onChange={handlePinChange} className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-isober-600 sm:text-sm/6" />
                                </div>
                            </div>

                            {displayError && <p className="text-red-500 text-sm">{displayError}</p>}

                            <div>
                                <button type="submit" disabled={!isPinValid} className="flex w-full justify-center rounded-md bg-isober-800 hover:bg-isober-900 disabled:opacity-50 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-isober-600">
                                    Submit
                                </button>
                            </div>
                        </form>
                        }

                        {/* debug */}
                        {/* responseData && <div>{JSON.stringify(responseData)}</div> */}

                        {responseData && <div>
                            <p className="text-isober-900 font-bold mb-5">Certificate information</p>
                            <table className="w-full border border-isober-900">
                                <tbody>
                                    <tr className="border border-isober-900">
                                        <td className="p-2 font-semibold border border-isober-900 whitespace-nowrap">Certificate number</td>
                                        <td className="p-2 border border-isober-900">{responseData.number}</td>
                                    </tr>
                                    <tr className="border border-isober-900">
                                        <td className="p-2 font-semibold border border-isober-900 whitespace-nowrap">Issued to</td>
                                        <td className="p-2 border border-isober-900">{responseData.customer}</td>
                                    </tr>
                                    <tr className="border border-isober-900">
                                        <td className="p-2 font-semibold border border-isober-900 whitespace-nowrap">Model</td>
                                        <td className="p-2 border border-isober-900">{responseData.model}</td>
                                    </tr>
                                    <tr className="border border-isober-900">
                                        <td className="p-2 font-semibold border border-isober-900 whitespace-nowrap">Serial numbers</td>
                                        <td className="p-2 border border-isober-900">
                                            {Array.isArray(responseData.serials) ? responseData.serials.join(", ") : "N/A"}
                                        </td>
                                    </tr>
                                    <tr className="border border-isober-900">
                                        <td className="p-2 font-semibold border border-isober-900 whitespace-nowrap">Calibration date</td>
                                        <td className="p-2 border border-isober-900">
                                            {new Date(responseData.calibrationDate).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "long",
                                                year: "numeric",
                                            })}
                                        </td>
                                    </tr>
                                    <tr className="border border-isober-900">
                                        <td className="p-2 font-semibold border border-isober-900 whitespace-nowrap">Expiry date</td>
                                        <td className="p-2 border border-isober-900">
                                            {new Date(responseData.expiry).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "long",
                                                year: "numeric",
                                            })}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}
