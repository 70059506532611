import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, XIcon } from '@heroicons/react/outline'
// import { format } from 'date-fns';

export default function QueueActionDialog({ open, handler, status }) {
    const [showOpen, setShowOpen] = useState(open)

    React.useEffect(() => {
        setShowOpen(open);
    }, [open])

    // FUNCTIONS

    function closeDialog(action) {
        setShowOpen(false);
        handler(action);
    }

    return (
        <Transition.Root show={showOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => { /* no-op*/ }}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500" onClick={() => closeDialog(false, undefined)} >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-isober-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationIcon className="h-6 w-6 text-isober-600" aria-hidden="true" />
                                </div>
                                <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Check certificate status
                                    </Dialog.Title>

                                    <div className="mt-2">
                                        {status === 'has_generated' && <p className='mb-2'>A certificate has already been generated for this calibration.</p>}
                                        {status === 'not_generated' && <p className='mb-2'>A certificate has not yet been generated for this calibration.</p>}
                                        {status === 'too_old' && <p className='mb-2'>A certificate has not yet been generated for this calibration but the calibration is now too old to be valid.</p>}
                                        {status !== 'has_generated' && status !== 'not_generated' && status !== 'too_old' && <p className='mb-2'>The certificate generation status for this calibration is unknown.</p>}
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                {status === 'not_generated' && <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-600 text-base font-medium text-white hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => closeDialog('retry')} >
                                        Retry certificate
                                    </button>}
                                {(status === 'has_generated' || status === 'too_old') && <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-isober-600 text-base font-medium text-white hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => closeDialog('remove')} >
                                    Remove from queue
                                </button>}
                                <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:mt-0 sm:w-auto sm:text-sm" onClick={() => closeDialog(undefined)} >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}