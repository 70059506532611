import React from "react";
import PageHeader from "../Components/PageHeader/index.js";
import { useLocation } from "react-router-dom";
import useUsersList from "./useUsersList.js";
import { useRecentCalibrations } from "./useRecentCalibrations.js";

export default function ReportsPage(props) {
    const currentLocation = useLocation();
    const people = useUsersList() || []; // Ensure it's an array
    const data = useRecentCalibrations() || []; // Ensure it's an array

    if (!Array.isArray(data) || !Array.isArray(people)) {
        return <p>Loading...</p>; // Prevents errors if data is still loading
    }

    // Step 1: Extract unique users and map IDs to names
    const uniqueUsers = [
        ...new Set(data.map((entry) => entry.authUserGuid)),
    ]
        .filter((userId) => userId !== "cuUZFxCtUTffgZmooepafBYLCjA3") // Exclude the test user
        .map((userId) => {
            const user = people.find((p) => p.id === userId);
            return { id: userId, name: user ? `${user.fname}` : "Unknown User" };
        });

    // Step 2: Extract unique model names
    const uniqueModels = [...new Set(data.map((entry) => entry.modelName))];

    // Step 3: Create a count lookup table
    const countTable = {};
    uniqueModels.forEach((model) => {
        countTable[model] = {};
        uniqueUsers.forEach((user) => {
            countTable[model][user.id] = 0; // Initialize to 0
        });
    });

    // Step 4: Populate the counts
    data.forEach(({ modelName, authUserGuid }) => {
        if (countTable[modelName] && countTable[modelName][authUserGuid] !== undefined) {
            countTable[modelName][authUserGuid] += 1;
        }
    }); // cuUZFxCtUTffgZmooepafBYLCjA3

    return (
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            <div className="py-6 px-6">
                <PageHeader location={currentLocation.pathname} />

                <p className="py-3 font-bold">Calibrations by user: 7 days</p>

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <table className="border-collapse border border-gray-300 w-full">
                        <thead>
                            <tr>
                                <th className="border border-gray-300 p-2">Model Name</th>
                                {uniqueUsers.map((user) => (
                                    <th key={user.id} className="border border-gray-300 p-2">
                                        {user.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {uniqueModels.map((model) => (
                                <tr key={model}>
                                    <td className="border border-gray-300 p-2">{model}</td>
                                    {uniqueUsers.map((user) => (
                                        <td key={user.id} className="border border-gray-300 p-2 text-center">
                                            {countTable[model]?.[user.id] || 0}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </main>
    );
}
