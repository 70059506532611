import React, { useState, useEffect } from "react";

const b64toBlob = (b64Data, contentType = "image/jpeg", sliceSize = 512) => {
  
  // Check for "data:image/png;base64," or similar prefixes and remove them
  const base64Prefix = "data:image/png;base64,";
  if (b64Data.startsWith(base64Prefix)) {
    b64Data = b64Data.substring(base64Prefix.length);
    contentType = "image/jpeg";
  }

  // Clean up any unwanted spaces or newlines
  var cleanedData = b64Data.replace(/\s+/g, "");

  // Base64 valid characters (A-Z, a-z, 0-9, +, /, and = for padding)
  const validBase64Chars = /^[A-Za-z0-9+/=]*$/;

  // Find invalid characters and log them for debugging
  const invalidChars = [];
  for (let i = 0; i < cleanedData.length; i++) {
    if (!validBase64Chars.test(cleanedData[i])) {
      invalidChars.push(cleanedData[i]);
    }
  }

  if (invalidChars.length > 0) {
    console.log("Invalid characters in base64 string:", invalidChars);
  }

  // Ensure padding is correct by adding necessary '=' if needed
  const padding = cleanedData.length % 4;
  if (padding > 0) {
    cleanedData += "=".repeat(4 - padding); // Add padding if necessary
  }

  // Now decode the base64 string safely
  const byteCharacters = atob(cleanedData); // Decode base64 string to binary string
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Uint8Array([...slice].map(c => c.charCodeAt(0)));
    byteArrays.push(byteNumbers);
  }

  return new Blob(byteArrays, { type: contentType });
};



// const b64toBlob = (b64Data, contentType = "image/jpeg", sliceSize = 512) => {
//   // Clean the base64 string and ensure padding
//   const base64Regex = /[^A-Za-z0-9+/=]/g;
//   const cleanedData = b64Data.replace(base64Regex, "");

//   const padding = cleanedData.length % 4;
//   if (padding > 0) {
//     cleanedData += "=".repeat(4 - padding); // Pad with "=" if necessary
//   }

//   const byteCharacters = atob(cleanedData); // Decode base64 string to a binary string
//   const byteArrays = [];

//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     const slice = byteCharacters.slice(offset, offset + sliceSize);
//     const byteNumbers = new Uint8Array([...slice].map(c => c.charCodeAt(0))); 
//     byteArrays.push(byteNumbers);
//   }

//   return new Blob(byteArrays, { type: contentType });
// };

export const Base64Image = ({ base64Data }) => {
  const [imageUrl, setImageUrl] = useState("");
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (!base64Data) {
      setImageUrl("");
      return;
    }

    try {
      const blob = b64toBlob(base64Data, "image/jpeg"); // Convert base64 to Blob
      const url = URL.createObjectURL(blob); // Create object URL from Blob
      setImageUrl(url); // Update the state with the image URL

      // Cleanup previous object URL to prevent memory leaks when base64Data changes
      return () => URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error decoding base64:", error); // Log any error that occurs during decoding
      setHasError(true);
      setImageUrl(""); // Fallback if decoding fails
    }
  }, [base64Data]); // Dependency array ensures effect runs when base64Data changes

  return (
    <dd className="mt-1 text-sm text-gray-900">
      {(imageUrl) ? <img className="w-[10rem]" src={imageUrl} alt="Signature" /> : "Not set"}
      {hasError === true && <span> - error loading signature</span>}
    </dd>
  );
};
